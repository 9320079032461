import { InjectionKey } from "vue";
import { useStore as baseUseStore, Store } from "vuex";
import {
	User,
	UserActions,
	UserGetters,
	UserMutations,
	UserState
} from "../types";

export const key: InjectionKey<Store<UserState>> = Symbol();

export const emptyUser = (): User => ({
	username: "",
	email: "",
	access_token: "",
	expires_at: "",
	refresh_token: "",
	token_type: ""
});

export default {
	state: {
		user: JSON.parse(
			window.localStorage.getItem("user") || JSON.stringify(emptyUser())
		)
	},
	mutations: {
		[UserMutations.SET_USER]: (state: UserState, currentUser: User) => {
			if (!currentUser) {
				state.user = emptyUser();
				window.localStorage.removeItem("user");
				return;
			}
			state.user = {
				username: currentUser.username,
				email: currentUser.email,
				access_token: currentUser.access_token,
				expires_at: currentUser.expires_at,
				refresh_token: currentUser.refresh_token,
				token_type: currentUser.token_type
			};
			window.localStorage.setItem("user", JSON.stringify(state.user));
		}
	},
	actions: {
		[UserActions.UPDATE_USER]: ({ commit }: any, payload: User) => {
			commit(UserMutations.SET_USER, payload);
		}
	},
	getters: {
		[UserGetters.GET_USER_STATUS]: (state: UserState) => !!state.user,
		[UserGetters.GET_USER]: (state: UserState): User => state.user,
		[UserGetters.IS_LOGGED_IN]: (state: UserState) => {
			if (
				!state.user?.username ||
				!state.user?.email ||
				!state.user?.access_token
			)
				return false;
			return true;
		}
	}
};

// define your own `useStore` composition function
export function useUserStore() {
	return baseUseStore(key);
}
