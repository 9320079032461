import { InjectionKey } from "vue";
import { useStore as baseUseStore, Store } from "vuex";
import {
	File,
	InputFile,
	FileID,
	FileType,
	FileMutations,
	FileActions,
	FileGetters,
	FileState
} from "../types";
import { publishFile, updateFile } from "@/services/git";
import { Sha1 } from "@/services/encode";

const hashCode = (str: string): number => {
	let hash = 0;
	let i;
	let chr;
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

const hashFile = (file: File | InputFile): number => {
	const content =
		(file as File).content || (file as InputFile).object.text || "";
	const name = file.name;
	return hashCode(JSON.stringify({ name, content }));
};

const isUpdated = (file: File | undefined): boolean => {
	if (!file || !file.content) return false;
	return file.og !== hashFile(file);
};

export const emptyUser = (): File => ({
	id: "",
	name: "",
	type: FileType.UNKNOWN,
	content: "",
	parent: "",
	og: "",
	path: "",
	sha: ""
});

export const key: InjectionKey<Store<FileState>> = Symbol();

export default {
	state: {
		files: []
	},
	mutations: {
		[FileMutations.SET_FILES]: (state: FileState, payload: InputFile[]) => {
			state.files = payload.map((entry: InputFile) => ({
				id: entry.oid,
				name: entry.name,
				type: entry.type == "tree" ? FileType.DIR : FileType.FILE,
				content: entry.object.text || "",
				parent: entry.parent || "",
				og: hashFile(entry),
				path: entry.path,
				sha: Sha1.encode(entry.object.text || "")
			}));
		},
		[FileMutations.SAVE_FILE]: async (state: FileState, payload: File) => {
			const fileIndex = state.files.findIndex(f => f.id === payload.id);
			// const file = state.files.find(f => f.id === payload.id);

			// console.log(fileIndex, file);

			// Do Commit action here;
			// when done

			if (fileIndex !== -1) {
				const updatedFile = await updateFile(payload);
				state.files.splice(fileIndex, 1, {
					...payload,
					og: hashFile(payload),
					id: updatedFile.content.oid
				});
			}
		},
		[FileMutations.UPDATE_FILE]: (state: FileState, payload: File) => {
			const index = state.files.findIndex(file => file.id == payload.id);
			state.files[index] = payload;
		},
		[FileMutations.PUBLISH_FILE]: async (
			state: FileState,
			payload: File
		): Promise<boolean> => {
			console.log("Publishing Mutation", payload);
			const publishedFile = await publishFile(payload);
			return true;
		}
	},
	actions: {
		[FileActions.SET_FILES]: ({ commit }: any, payload: File[]) =>
			commit(FileMutations.SET_FILES, payload),
		[FileActions.SAVE_FILE]: ({ commit }: any, payload: File) =>
			commit(FileMutations.SAVE_FILE, payload),
		[FileActions.UPDATE_FILE]: ({ commit }: any, payload: File) =>
			commit(FileMutations.UPDATE_FILE, payload),
		[FileActions.PUBLISH_FILE]: ({ commit }: any, payload: File) =>
			commit(FileMutations.PUBLISH_FILE, payload),
		[FileActions.PUBLISH]: ({ commit }: any) =>
			commit(FileMutations.PUBLISH)
	},
	getters: {
		[FileGetters.GET_FILES]: (state: FileState): File[] =>
			state.files.map(file => ({
				...file,
				updated: isUpdated(file)
			})),

		[FileGetters.GET_FILE_BY_ID]: (state: FileState) => (
			id: string
		): File | undefined => state.files.find(file => file.id === id),

		[FileGetters.GET_FILE_BY_PATH]: (state: FileState) => (
			path: string
		): File | undefined => state.files.find(file => file.path == path),

		[FileGetters.IS_FILE_UPDATE]: (state: FileState) => (
			id: string
		): boolean => isUpdated(state.files.find(file => file.id == id))
	}
};

// define your own `useStore` composition function
export function useFileStore() {
	return baseUseStore(key);
}
