import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Home from "@/views/Home.vue";

import { useStore } from "../store";

const routeGuard = (to: any, from: any, next: any) => {
	const store = useStore();

	const isLoggedIn = true;
	if (to.name !== "Login" && !isLoggedIn) next({ name: "Home" });
	else next();
};

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/files",
		name: "Files",
		component: () =>
			import(/* webpackChunkName: "files" */ "../views/Files.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/file/:path",
		name: "FileView",
		component: () =>
			import(/* webpackChunkName: "files" */ "../views/Files.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/callback",
		name: "Callback",
		component: () =>
			import(/* webpackChunkName: "callback" */ "../views/Callback.vue")
	},
	{
		path: "/error",
		name: "Error",
		component: () =>
			import(/* webpackChunkName: "error" */ "../views/Error.vue")
	},
	{
		path: "/about",
		name: "About",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue")
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
