import { Octokit as OctoGraph } from "@octokit/core";
import { Octokit as OctoRestLoad } from "@octokit/rest";
import { GraphQlQueryResponseData } from "@octokit/graphql";
import { createPullRequest } from "octokit-plugin-create-pull-request";

require("dotenv").config();

import { Base64 } from "./encode";
import { AllFiles } from "../queries";
import { File } from "../types";

const OctoRest = OctoRestLoad.plugin(createPullRequest);

const octokitGraphQL = new OctoGraph({
	auth: process.env.VUE_APP_GITHUB_TOKEN
});
const octokitRest = new OctoRest({ auth: process.env.VUE_APP_GITHUB_TOKEN });

const gitConfig = {
	owner: process.env.VUE_APP_GITHUB_USER || "none",
	repo: process.env.VUE_APP_GITHUB_REPOSITORY || "none",
	stageBranch: process.env.VUE_APP_GITHUB_STAGE_BRANCH || "unpublished",
	prodBranch: process.env.VUE_APP_GITHUB_PROD_BRANCH || "master"
};

export const getFiles = async () => {
	const files: File[] = [];

	const response: GraphQlQueryResponseData = await octokitGraphQL.graphql(
		AllFiles,
		{
			owner: gitConfig.owner,
			name: gitConfig.repo
		}
	);

	const goThroughFiles = (entries: any[], parent: "") => {
		entries.forEach(entry => {
			files.push({ ...entry, parent });
			if (entry.type == "tree")
				goThroughFiles(entry.object.entries, entry.oid);
		});
	};
	goThroughFiles((response as any).repository.object.entries, "");

	return files;
};

export const updateFile = async (file: File) => {
	if (file.content) {
		try {
			const { data } = await octokitRest.repos.createOrUpdateFileContents(
				{
					owner: gitConfig.owner,
					repo: gitConfig.repo,
					path: file.path,
					message: `Update ${file.name}`,
					content: Base64.encode(file.content),
					sha: file.id,
					branch: gitConfig.stageBranch
				}
			);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}
};

export const createFile = async (file: File) => {
	if (file.content) {
		try {
			const { data } = await octokitRest.repos.createOrUpdateFileContents(
				{
					owner: gitConfig.owner,
					repo: gitConfig.repo,
					path: file.path,
					message: `Create ${file.name}`,
					content: Base64.encode(file.content),
					sha: file.id,
					branch: gitConfig.stageBranch
				}
			);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}
};

export const publishFile = async (file: File) => {
	console.log(`Publishing File ${file.name}`);
	try {
		await octokitRest
			.createPullRequest({
				owner: gitConfig.owner,
				repo: gitConfig.repo,
				title: `Publish ${file.name}`,
				body: `Publish ${file.name}`,
				base: gitConfig.prodBranch,
				head: gitConfig.stageBranch,
				changes: [
					{
						files: {
							[file.path]: {
								content: Base64.encode(file.content || ""),
								encoding: "base64"
							}
						},
						commit: "Publish All!"
					}
				]
			})
			.then(pr => console.log(pr ? pr.data.number : null));
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const publishAll = async (file: File) => {
	try {
		await octokitRest
			.createPullRequest({
				owner: gitConfig.owner,
				repo: gitConfig.repo,
				title: `Publish ${file.name}`,
				body: `Publish ${file.name}`,
				base: gitConfig.prodBranch,
				head: gitConfig.stageBranch,
				changes: [
					{
						files: {
							[file.path]: {
								content: Base64.encode(file.content || ""),
								encoding: "base64"
							}
						},
						commit: "Publish All!"
					}
				]
			})
			.then(pr => console.log(pr ? pr.data.number : null));
	} catch (error) {
		console.error(error);
		return error;
	}
};
