import { InjectionKey } from "vue";
import {
	createStore,
	useStore as baseUseStore,
	Store,
	createLogger
} from "vuex";

import FileStore from "./files";
import UserStore from "./user";

import { FileState, UserState } from "../types";

interface State extends FileState, UserState {}

export const key: InjectionKey<Store<State>> = Symbol();
export const store = createStore<State>({
	strict: true,
	modules: {
		files: {
			namespaced: true,
			...FileStore
		},
		user: {
			namespaced: true,
			...UserStore
		}
	},
	plugins: [createLogger()]
});

// define your own `useStore` composition function
export function useStore() {
	return baseUseStore(key);
}
