<template>
	<section class="section">
		<div class="container">
			<h1>Welcome to MaMa</h1>
			<p>
				MaMa helps you to manage and easily edit and preview your
				Markdown content.
			</p>
			<p>
				Edit, save and publish from here and let Netlify publish your
				blog without any effort. Manage your website, blog or other
				project content, just with Netlify and Github.
			</p>
		</div>
	</section>
</template>
<script>
export default {
	methods: {
		login() {
			console.log("jemoeder");
		}
	}
};
</script>
