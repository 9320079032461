<template>
	<input class="navigation-input" type="checkbox" id="nav-toggle" />
	<label class="navigation-toggle" for="nav-toggle"><span></span></label>
	<div class="navigation-background"></div>
	<header class="header">
		<a class="logo" href="/">{{ title }}</a>

		<div class="navigation">
			<template v-if="isLoggedIn">
				<router-link to="/files"><span>Files</span></router-link>
				<button
					v-if="isLoggedIn"
					@click="triggerNetlifyIdentityAction('logout')"
				>
					<span>Log out</span>
				</button>
				<!-- <span>Hello {{ currentUser.username }}</span> -->
			</template>
			<template v-else>
				<router-link to="/about">About</router-link>
				<button
					v-if="!isLoggedIn"
					@click="triggerNetlifyIdentityAction('login')"
				>
					<span>Log in</span>
				</button>
			</template>
		</div>
	</header>
	<main class="content">
		<router-view />
	</main>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import netlifyIdentity from "netlify-identity-widget";

import { InputUser } from "./types";

import { useStore } from "./store";
import router from "./router";

netlifyIdentity.init({
	APIUrl: process.env.VUE_APP_NETLIFY_IDENTITY_URL,
	logo: false
});

export default defineComponent({
	name: "App",
	setup() {
		const store = useStore();
		const isLoggedIn = computed(() => store.getters["user/isLoggedIn"]);
		const currentUser = computed(() => store.getters["user/getUser"]);

		const triggerNetlifyIdentityAction = (action: any) => {
			if (action == "login" || action == "signup") {
				netlifyIdentity.open(action);
				netlifyIdentity.on(action, (user: InputUser) => {
					const loginUser = {
						username: user.user_metadata.full_name,
						email: user.email,
						access_token: user.token.access_token,
						expires_at: user.token.expires_at,
						refresh_token: user.token.refresh_token,
						token_type: user.token.token_type
					};
					store.dispatch("user/UPDATE_USER", loginUser);
					netlifyIdentity.close();
				});
			} else if (action == "logout") {
				store.dispatch("user/UPDATE_USER", {});

				netlifyIdentity.logout();
				router.push({ name: "Home" });
			}
		};
		const title = process.env.VUE_APP_MAMA_TITLE || "MaMa";

		return {
			title,
			currentUser,
			isLoggedIn,
			triggerNetlifyIdentityAction
		};
	}
});
</script>

<style lang="scss" >
@import "@/assets/app.scss";
.navigation {
	margin: var(--space);
	color: white;
	border-radius: calc(var(--border-radius) * 2);
	background-color: black;
	a {
		color: currentColor;
	}
	button {
		background-color: transparent;
		border: none;
		color: inherit;
		font-size: inherit;
	}
}
</style>
