import { createApp } from "vue";
import router from "./router";
import { store, key } from "./store";
import App from "./App.vue";

const app = createApp(App)
	.use(router)
	.use(store, key);

app.directive("auto-size", {
	mounted(el) {
		const offset = el.offsetHeight - el.clientHeight;
		el.style.height = "auto";
		el.style.height = el.scrollHeight + offset + "px";
	},
	updated(el) {
		const offset = el.offsetHeight - el.clientHeight;
		el.style.height = "auto";
		el.style.height = el.scrollHeight + offset + "px";
	}
});

app.mount("#app");
