// {
//     "owner": "silvandiepen",
//     "name": "mama-content"
//   }

export const AllFiles = `
query RepoFiles($owner: String!, $name: String!) {
    repository(owner: $owner, name: $name) {
        object(expression: "${process.env.VUE_APP_GITHUB_STAGE_BRANCH}:") {
            ... on Tree {
                entries {
                    name
                    oid
                    type
                    mode
                    path

                    object {
                        ... on Blob {
                            text
                            
                        }
                        ... on Tree {
                            entries {
                                name
                                oid
                                type
                                mode
                                path
            
                                object {
                                    ... on Blob {
                                        text
                                        
                                    }
                                    ... on Tree {
                                        entries {
                                            name
                                            oid
                                            type
                                            mode
                                            path
                        
                                            object {
                                                ... on Blob {
                                                    text
                                                    
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;
