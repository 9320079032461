export type FileID = string;
export type FileName = string;
export type FileContent = string;
export type FileParent = FileID;
export type FileOriginal = string | number;
export type FilePath = string;

export enum FileType {
	FILE = "file",
	DIR = "tree",
	UNKNOWN = ""
}

export interface File {
	name: FileName;
	id: FileID;
	content?: FileContent;
	type: FileType;
	parent: FileParent;
	og: FileOriginal;
	updated?: boolean;
	path: FilePath;
	sha: string;
}
export interface InputFileObject {
	text: string;
}
export interface InputFile {
	oid: string;
	name: string;
	type: string;
	object: InputFileObject;
	parent?: string;
	path: string;
}

export interface Meta {
	[x: string]: string | string[] | any;
}
export interface MarkdownData {
	meta?: Meta;
	document?: string;
}

// User Related
interface UserMeta {
	full_name: string;
}
interface UserToken {
	access_token: string;
	expires_at: string;
	refresh_token: string;
	token_type: string;
}
export interface InputUser {
	user_metadata: UserMeta;
	token: UserToken;
	email: string;
}

export interface User {
	username: string;
	email: string;
	access_token: string;
	expires_at: string;
	refresh_token: string;
	token_type: string;
}

export interface FileState {
	files: File[];
}
export enum FileGetters {
	GET_FILES = "getFiles",
	GET_FILE_BY_ID = "getFileById",
	GET_FILE_BY_PATH = "getFileByPath",
	IS_FILE_UPDATE = "isFileUpdated"
}
export enum FileActions {
	SET_FILES = "setFiles",
	SAVE_FILE = "saveFile",
	UPDATE_FILE = "updateFile",
	PUBLISH_FILE = "publishFile",
	PUBLISH = "publish"
}

export enum FileMutations {
	SET_FILES = "setFiles",
	SAVE_FILE = "saveFile",
	UPDATE_FILE = "updateFile",
	PUBLISH_FILE = "publishFile",
	PUBLISH = "publish"
}
export interface UserState {
	user: User;
}

export enum UserGetters {
	GET_USER_STATUS = "getUserStatus",
	GET_USER = "getUser",
	IS_LOGGED_IN = "isLoggedIn"
}

export enum UserActions {
	UPDATE_USER = "updateUser"
}
export enum UserMutations {
	SET_USER = "setUser"
}
